<template>
    <div class="post__gallery">
        <swiper
            ref="swiperTop"
            class="swiper gallery-top"
            :options="swiperOptionTop"
        >
            <swiper-slide
                v-for="(img,index) in imgList"
                :key="'top_img_' + index"
                :style="{backgroundImage: `url(${img.file})`}"
            />

            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        </swiper>

        <swiper
            ref="swiperThumbs"
            class="swiper gallery-thumbs"
            :options="swiperOptionThumbs"
        >
            <swiper-slide
                v-for="(img, index) in imgList"
                :key="'thumbs_img_' + index"
                :style="{backgroundImage: `url(${img.file})`}"
            />
        </swiper>
    </div>
</template>

<script>
    export default {
        name: 'Gallery',
        props: {
            imgList: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                swiperOptionTop: {
                    loop: true,
                    loopedSlides: 5,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    centeredSlides: true,
                    mousewheel: false
                },
                swiperOptionThumbs: {
                    loop: true,
                    loopedSlides: 5,
                    spaceBetween: 10,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    touchRatio: 0.2,
                    slideToClickedSlide: true
                }
            };
        },
        mounted() {
            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.$swiper;
                const swiperThumbs = this.$refs.swiperThumbs.$swiper;

                swiperTop.controller.control = swiperThumbs;
                swiperThumbs.controller.control = swiperTop;
            });
        }
    };
</script>

<style lang="scss" scoped>
    .post__gallery {
        height: 500px;
        background-color: #fff;
    }
    .swiper {
        .swiper {
            &-slide {
                background-size: cover;
                background-position: center;
            }

            &-button {
                &-prev,
                &-next {
                    color: #fbc04f;
                    text-shadow: 0px 1px 3px #000;
                }
            }
        }

        &.gallery-top {
            height: 80%;
            width: 100%;
            .swiper-slide {
                width: 100% !important;
            }
        }
        &.gallery-thumbs {
            height: 20%;
            box-sizing: border-box;
            padding: 10px 0;
        }
        &.gallery-thumbs .swiper-slide {
            width: 25%;
            height: 100%;
            opacity: 0.4;
        }
        &.gallery-thumbs .swiper-slide-active {
            opacity: 1;
        }
    }
</style>
